import InputGlobal from '@/components/incident/inputs/InputGlobal.vue';
export default {
	name: 'FormHeader',
	props: {
		headers: { type: [Array], required: false },
	},
	data: () => ({
		inputs: [],
	}),
	created() {
		this.inputs = this.headers;
	},
	components: {
		InputGlobal,
	},
};
