import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import FormHeader from '@/components/incident/headers/FormHeader.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CbodyTextarea from '@/components/incident/inputs/CbodyTextarea.vue';
import Swal from 'sweetalert2';
import InvolvedForm from '@/components/incident/bodies/InvolvedForm/InvolvedForm.vue';
import textTransform from '@/helpers/textTransform.js';
import RegistryCreator from '@/components/global/RegistryCreator.vue';

export default {
	name: 'IncidenteShow',
	data: () => ({
		steps: {
			current: 1,
			views: [1],
		},
		headers: [
			{
				text: 'Nombre',
				value: 'name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Evaluación',
				value: 'evaluation',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Acciones',
				value: 'actions',
				sortable: false,
				align: 'end',
				class: 'primary--text',
			},
		],
		validationErrors: [],
		toolsInspection: [],
		toolDialog: false,
		evaluationToolDialog: false,
		toolEvaluation: null,
		isLoadingData: false,
		isLoadingSave: false,
		files: null,
		evidences: [],
		evidencesSaved: [],
		bodies: null,
		involved: [],
	}),
	computed: {
		//...mapState('incident', ['incidentFormat']),
		...mapState('inspection', ['inspection']),
		...mapState('register', ['register']),
		...mapState('security', ['managements', 'costCenters']),

		breadcrumbs: function () {
			return [
				{
					text: 'Reporte inmediato de incidente y/o falla de control',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentList',
					},
				},
				{
					text: 'Registro',
					disabled: true,
					href: '/',
				},
			];
		},
		isCompletedInspectons: function () {
			return this.isCompleteInspection();
		},
		listEvidences: function () {
			return [...this.evidences, ...this.evidencesSaved];
		},
		incidentFormat: {
			get() {
				return this.$store.state.incident.incidentFormat;
			},
			set(value) {
				this.$store.dispatch('incident/setIncidentValues', value);
			},
		},
		isCompletedLoad() {
			const costCenters = this.$store.state.security.costCenters;
			const usersActive = this.$store.getters['security/getUsers'];
			const incident = this.$store.state.incident.incident;
			if (
				Array.isArray(costCenters) &&
				costCenters.length > 0 &&
				Array.isArray(usersActive) &&
				usersActive.length > 0 &&
				incident
			) {
				this.$nextTick(() => {
					setTimeout(function () {
						window.print();
						window.close();
					}, 500);
				});
				return true;
			} else {
				return false;
			}
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		//...mapActions('inspection', ['find', 'cleanInspection']),
		//...mapActions('register', ['create', 'findById']),
		//...mapActions('tool', ['listTools']),
		...mapActions('incident', [
			'getFormat',
			'getIncident',
			'saveFiles',
			'updateIncident',
			'cleanIncident',
			'approveIncident',
		]),

		textTransform,

		initialize() {
			$EventBus.$emit('showSubNav', false);
			this.loadFormat();

			// this.find({
			// 	success: () => {
			// 		this.isLoadingData = false;
			// 	},
			// 	fail: () => {
			// 		this.isLoadingData = false;
			// 	},
			// 	id: this.$route.params?.inspectionId,
			// });
			// this.listTools();
		},
		async loadFormat() {
			this.isLoadingData = true;
			const { ok } = await this.getIncident({
				id: this.$route.params.incidentId,
				setValue: true,
			});
			if (ok) {
				if (this.$store.state.incident.incidentFormat) {
					const evidences =
						this.$store.state.incident?.incident?.evidences || [];
					this.bodies = [...this.$store.state.incident?.incidentFormat?.bodies];
					this.involved = [...this.$store.state.incident?.incidentFormat?.involved];
					this.evidencesSaved = evidences.map((evidence) => {
						return {
							id: evidence.id,
							description: evidence.description,
							urlImage: evidence.file,
							urlPublic: evidence.file,
						};
					});
				}

				this.incidentFormat.headers.forEach((header) => {
					header.disabled = true;
				});
				// const headers = this.$store.state.incident.incidentFormat.headers;
				// if (Array.isArray(headers)) {
				// 	headers.forEach((header) => {
				// 		this.$store.dispatch('incident/updateInputHeader', {
				// 			id: header.header_id,
				// 			value: header.value,
				// 			disabled: true,
				// 		});
				// 	});
				// }
			}
			// const { ok } = await this.getFormat();
			// console.log("asd",ok)
			// if (ok) {

			// }
			this.isLoadingData = false;
		},
		validateHeader() {
			let headers = this.$store.state.incident?.incidentFormat?.headers || null;
			let result = true;
			if (headers && Array.isArray(headers)) {
				headers.forEach((header) => {
					if (
						!header.value ||
						header.value === undefined ||
						header.value == ''
					) {
						result = false;
					}
				});
			}
			return result;
		},
		validateBody(body = []) {
			let isCompleted = true;
			if (body?.group) {
				if (Array.isArray(body.items)) {
					body.items.forEach((item) => {
						if (
							!item?.value ||
							(Array.isArray(item?.value) && item?.value.length == 0)
						) {
							isCompleted = false;
						} else if (
							(item?.value || '').includes('Otros') &&
							!item.otherText
						) {
							isCompleted = false;
						}
					});
				}
			} else if (body?.group == null) {
				if (Array.isArray(body?.value)) {
					body?.value.forEach((description) => {
						if (!description.text) {
							isCompleted = false;
						}
					});
				} else {
					isCompleted = false;
				}
			}
			return isCompleted;
		},
		async saveRegister(status) {
			try {
				await this.saveImages();
				const service_id = this.findValueInArray({
					items: this.incidentFormat?.headers,
					prop: 'key',
					val: 'service',
					propFound: 'value',
				});
				const date = this.findValueInArray({
					items: this.incidentFormat?.headers,
					prop: 'key',
					val: 'date',
					propFound: 'value',
				});
				const management_id = this.findValueInArray({
					items: this.costCenters,
					prop: 'id',
					val: service_id,
					propFound: 'management_id',
				});
				let data = {
					report: {
						date,
						service_id: parseInt(service_id) || null,
						management_id,
						status,
						company_id: parseInt(localStorage.getItem('company_id')),
					},
					fields: [],
					evidences: [],
				};
				data.fields = this.incidentFormat.headers.map((header) => {
					return {
						incident_field_id: header.id,
						value: (header.value || '').toString() || null,
					};
				});

				this.bodies.forEach((body) => {
					if (body?.group) {
						if (Array.isArray(body.items)) {
							body.items.forEach((item) => {
								let newValue = { values: item?.value };
								if ((item?.value || '').includes('Otros')) {
									newValue.otherText = item.otherText;
								}
								data.fields.push({
									incident_field_id: item.id,
									value: JSON.stringify(newValue) || null,
								});
							});
						}
					} else {
						data.fields.push({
							incident_field_id: body.id,
							value: body.value || null,
						});
					}
				});

				data.evidences = this.listEvidences.map((evidence) => {
					if (evidence?.urlPublic) {
						return {
							id: evidence?.id || null,
							description: evidence?.description || null,
							file: evidence?.urlPublic,
						};
					}
				});
				this.sendIncident(data);
			} catch (error) {}
		},
		async sendIncident(data) {
			this.validationErrors = [];
			this.isLoadingSave = true;
			const { ok, error } = await this.updateIncident({
				incidentId: this.$route.params.incidentId,
				payload: data,
			});
			if (ok) {
				this.isLoadingSave = false;
				this.$router.push({
					name: 'IncidentList',
				});
				$EventBus.$emit(
					'showSnack',
					'success',
					'Se ha registrado satisfactoriamente.'
				);
			} else {
				this.isLoadingSave = false;
				if (error && error?.status == 400 && error?.data?.message) {
					this.validationErrors = Array.isArray(error?.data?.message)
						? error?.data?.message
						: [];
				} else {
					this.validationErrors = [
						'Algo salio mal, no se guardo la información.',
					];
				}
				$EventBus.$emit(
					'showSnack',
					'error',
					'Algo salio mal, no se guardo la información.'
				);
			}
		},
		findValueInArray({ items = [], prop = null, val = null, propFound = null }) {
			let found;
			if (Array.isArray(items) && prop && val) {
				found = items.find((item) => item[prop] == val);
				if (found && propFound) {
					found = found[propFound] ? found[propFound] : null;
				}
			}
			return found || null;
		},
		isCompleteInspection() {
			const headers = this.incidentFormat?.headers || [];
			const bodies = this.bodies || [];
			let incomplete = false;

			headers.forEach((header) => {
				if (!header?.value) {
					incomplete = true;
				}
			});
			if (bodies.length > 0) {
				bodies.forEach((body) => {
					if (!this.validateBody(body)) {
						incomplete = true;
					}
				});
			} else {
				incomplete = true;
			}
			return !incomplete;
		},
		generateId() {
			return Math.floor(Math.random() * 100) + Date.now();
		},
		importingFiles() {
			if (Array.isArray(this.files)) {
				this.files.forEach((file) => {
					this.evidences.push({
						id: null,
						currentId: this.generateId(),
						file,
						description: '',
						urlImage: URL.createObjectURL(file),
						urlPublic: null,
					});
				});
			}
			this.$nextTick(() => {
				this.files = null;
			});
		},
		deleteEvidence(evidence) {
			if (evidence.urlPublic) {
				const index = this.evidencesSaved.findIndex(
					(element) => element.id === evidence.id
				);
				if (index >= 0) {
					this.evidencesSaved.splice(index, 1);
				}
			} else {
				const index = this.evidences.findIndex(
					(element) => element.currentId === evidence.currentId
				);
				if (index >= 0) {
					this.evidences.splice(index, 1);
				}
			}
		},
		async saveImages() {
			if (Array.isArray(this.evidences) && this.evidences.length > 0) {
				this.isLoadingSave = true;

				let formData = new FormData();
				this.evidences.forEach((element) => {
					formData.append('files[]', element.file);
				});

				const { ok, response, error } = await this.saveFiles(formData);
				if (ok) {
					const files = response?.saved;
					if (Array.isArray(files) && files.length > 0) {
						files.forEach((file, index) => {
							this.evidences[index].urlPublic = file.Location;
						});
					}
				} else {
					console.log('message: ', error);
				}

				this.isLoadingSave = false;
			}
		},
		async approveRegister() {
			const result = await Swal.fire({
				title: '¿Está seguro que desea aprobar esta inspección?',
				text: 'Confirme acción',
				icon: 'question',
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'No, Cancelar',
				confirmButtonText: '¡Si, Aprobar!',
			});
			if (result.value) {
				this.isLoadingSave = true;
				const { ok, error } = await this.approveIncident({
					id: this.$route.params.incidentId,
				});
				if (ok) {
					this.isLoadingSave = false;
					this.$router.push({
						name: 'IncidentList',
					});
					$EventBus.$emit(
						'showSnack',
						'success',
						'Se ha registrado satisfactoriamente.'
					);
				} else {
					this.isLoadingSave = false;
					if (error && error?.status == 400 && error?.data?.message) {
						this.validationErrors = Array.isArray(error?.data?.message)
							? error?.data?.message
							: [];
					} else {
						this.validationErrors = [
							'Algo salio mal, no se guardo la información.',
						];
					}
					$EventBus.$emit(
						'showSnack',
						'error',
						'Algo salio mal, no se guardo la información.'
					);
				}
			}
		},
	},
	destroyed() {
		this.cleanIncident();
	},
	components: { FormHeader, ValidationAlert, CbodyTextarea, InvolvedForm, RegistryCreator },
};
