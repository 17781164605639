var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h6 primary--text",domProps:{"textContent":_vm._s('Reporte inmediato de incidente y/o falla de control')}}),_c('h2',{staticClass:"text-body-2 mb-0",domProps:{"textContent":_vm._s('HSEQ-S&SO1-F-9')}})])],1),_c('v-card',{staticClass:"my-10",attrs:{"elevation":"0"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(" Inicio ")]),_c('v-card-text',[(_vm.incidentFormat && _vm.incidentFormat.headers)?_c('form-header',{attrs:{"headers":_vm.incidentFormat.headers}}):_vm._e()],1)],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(" Involucrados ")]),_c('v-card-text',[_c('involved-form',{attrs:{"disabled":true},model:{value:(_vm.involved),callback:function ($$v) {_vm.involved=$$v},expression:"involved"}})],1)],1),_vm._l((_vm.bodies),function(body,index){return [_c('v-card',{key:index + '-card',attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.textTransform(body.group || body.label))+" ")]),_c('v-card-text',[(body.group)?_c('div',{},[_vm._l((body.items),function(input,inputi){return [_c('div',{key:inputi + 'input',staticClass:"mb-4"},[_c('p',[_vm._v(_vm._s(_vm.textTransform(input.label)))]),_vm._l((JSON.parse(
													input.values
												) || []),function(item,itemi){return [_c('v-checkbox',{key:itemi + 'chk',attrs:{"label":item.label,"value":item.value,"disabled":"","multiple":"","dense":""},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}),(
													(input.value || '').includes(
														'Otros'
													)
												)?_c('v-text-field',{attrs:{"outlined":"","hide-details":"","disabled":""},model:{value:(input.otherText),callback:function ($$v) {_vm.$set(input, "otherText", $$v)},expression:"input.otherText"}}):_vm._e(),_c('hr')],2)]})],2):_c('div',[_c('cbody-textarea',{attrs:{"label":body.label,"disabled":true},model:{value:(body.value),callback:function ($$v) {_vm.$set(body, "value", $$v)},expression:"body.value"}}),(index == _vm.bodies.length - 1)?_c('div',{staticClass:"my-4"},[_vm._l((_vm.listEvidences),function(evidence,evdindex){return [_c('div',{key:evdindex,staticClass:"py-8 px-4"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('img',{staticClass:"d-block",attrs:{"height":"auto","width":"100%","max-height":"auto","max-width":"100%","src":evidence.urlImage}})]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"rows":"2","outlined":"","label":"Descripción de la imagen","hide-details":"","disabled":""},model:{value:(
																evidence.description
															),callback:function ($$v) {_vm.$set(evidence, "description", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tevidence.description\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"}})],1)],1)],1)]})],2):_vm._e()],1)])],1)]}),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"px-8"},[_c('registry-creator',{attrs:{"register":_vm.incidentFormat}})],1)])],2)],1)],1)],1),_c('div',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.isCompletedLoad))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }