<template>
	<div v-if="Array.isArray(descriptions)">
		<div v-for="(description, indexx) in descriptions" :key="indexx">
			<v-row align="center">
				<v-col cols="10">
					<v-textarea
						v-model="description.text"
						rows="2"
						outlined
						label="Descripción"
						hide-details
						:key="indexx"
						:disabled="disabled"
						:placeholder="note || ''"
					></v-textarea>
				</v-col>
				<v-col cols="auto" v-if="!disabled">
					<v-icon
						@click="deleteDescription(indexx)"
						v-if="descriptions.length > 1"
					>
						mdi-delete
					</v-icon>
				</v-col>
			</v-row>
		</div>
		<div class="d-flex" v-if="!disabledButton">
			<v-btn color="primary" class="my-4" @click="addItem()" v-if="!disabled">
				Agregar Descripción
			</v-btn>
		</div>
	</div>
</template>
<script>
export default {
	name: 'CbodyTextarea',
	props: {
		value: {
			type: [String, Array],
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		note: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},		
		disabledButton: {
			type: Boolean,
			required: false,
		},
	},
	data: () => ({
		menu2: false,
	}),
	computed: {
		descriptions: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	created() {
		if (!this.descriptions) {
			this.descriptions = [{ text: '' }];
		} else if (
			typeof this.descriptions === 'string' ||
			this.descriptions instanceof String
		) {
			try {
				this.descriptions = JSON.parse(this.descriptions);
			} catch (error) {
				this.descriptions = [{ text: (this.descriptions || '').toString() }];
			}
		}
	},
	methods: {
		parseArray(value) {
			let response = [{ text: '' }];
			if (value) {
				if (typeof value === 'string' || value instanceof String) {
					try {
						response = JSON.parse(value);
					} catch (error) {
						response = [{ text: (value || '').toString() }];
					}
				} else if (Array.isArray(value)) {
					response = value;
				}
			}
			return response;
		},
		addItem() {
			this.descriptions.push({ text: '' });
		},

		deleteDescription(index) {
			this.descriptions.splice(index, 1);
		},
	},
};
</script>
