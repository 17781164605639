//import { $EventBus } from '@/main';
export default {
	name: 'InvolvedForm',
	props: {
		value: {
			type: Array,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			user: '',
			password: '',
			userRules: [(v) => !!v || 'Campo requerido'],
			passwordRules: [(v) => !!v || 'Campo requerido'],
			error: null,
			isLoadingData: false,
			involved: {
				document: null,
				user_id: null,
				charge: null,
			},
			headers: [
				{
					text: 'Documento',
					value: 'document',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'Nombre',
					value: 'user_id',
					sortable: false,
					align: 'center',
					class: 'primary--text',
				},
				{
					text: 'Cargo',
					value: 'charge',
					sortable: false,
					align: 'center',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'end',
					class: 'primary--text',
				},
			],
		};
	},
	created() {},
	mounted() {},
	computed: {
		involvedes: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		users() {
			return this.$store.getters['security/getUsers'] || [];
		},
	},
	watch: {},
	methods: {
		async validate() {
			let isvalidated = this.$refs.form.validate();
			const isDuplicated = this.involvedes.some(
				(element) => element.user_id == this.involved.user_id
			);
			if (isvalidated && !isDuplicated) {
				this.involvedes.push({ ...this.involved });
				this.reset();
			}
		},
		reset() {
			this.$nextTick(() => {
				this.$refs.form.reset();
			});
		},
		resetValidation() {
			this.$refs.form.resetValidation();
		},
		getUserName(id) {
			const user = (this.users || []).find((user) => user.id == id);
			return user?.name || '';
		},
		deleteItem(item) {
			const index = this.involvedes.findIndex(
				(element) => element.user_id === item.user_id
			);
			if (index >= 0) {
				this.involvedes.splice(index, 1);
			}
		},
	},
	components: {},
};
